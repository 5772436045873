<template>
  <div>
    <CRow>
      <CCol>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewApprovalSkillSets">New </button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
        :responsive="true">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     <template #skillSetName="{item}">
        <td class='font-weight-bold'
          v-if="item.skillSetName"
        >{{ item.skillSetName }}</td>
        <td v-else>--</td>
      </template>
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'ApprovalSkillSet', params: {approvalSkillSetId: item.approvalSkillSetId, mode: 'view'}}"> View </CDropdownItem>
           
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteing(item.approvalSkillSetId)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
 
      </CCol>
      </CRow>
 </div>
</template>

<script>
const fields = [
  'index',
 
  { key: "skillSetName", label: "Skill Set Name", _classes: 'font-weight-bold', _style: "min-width:200px" },
  
  { key: "createdTime",label: "Created Date & Time",_classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "ApprovalSkillSets",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getApprovalSkillSet() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/approval/skillSets")
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   
    deleteing(approvalSkillSetId) {
      console.log("delete called with", this.approvalSkillSetId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/approvalSkillSet/" +
         approvalSkillSetId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getApprovalSkillSet();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
    navToNewApprovalSkillSets(){
       this.$router.push({ name: "NewApprovalSkillSet", params: { mode: "new" } });
    }
  },
  mounted() {
    this.getApprovalSkillSet();
  },
};
</script>